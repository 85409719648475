import {Component} from 'react';
import axios from 'axios';
import Navbar from "./adnav.component";
import "./notes-list.css";
import "./print.css"

const Note = props => (
    <tr className="printnote">
        <td className="date"><strong>{(new Date(props.note.date)).toLocaleString()}</strong></td>
        <td className="note">{props.note.topic}</td>
        <td className="note">{props.note.details}</td>
    </tr>
)

export default class NotesList extends Component {
    constructor(props){
        super(props);
        
        this.changeView = this.changeView.bind(this);
        this.pullNotes = this.pullNotes.bind(this);

        this.state= {
            notes: [],
            view: "Day",
            lastevent: ""
        }
    }

    changeView(e) {
        if(this.state.lastevent !== "")
            this.state.lastevent.target.className = "circle";
        this.setState({
            view: e.target.name,
            lastevent: e
        });
        e.target.className = "view";
        this.pullNotes(e.target.name);  
    }

    componentDidMount(){
        this.pullNotes(this.state.view); 
    }

    notesList(){
        return this.state.notes.map(currentnote => {
            return <Note note={currentnote}/>
        });    
    }

    pullNotes(views){
        if (views!=="All"){
            axios.post("https://adits-notesapp.herokuapp.com/admin/notes/"+views,{owner: sessionStorage.getItem('token')})
                .then(response => {
                    this.setState({ notes: response.data });
                })
                .catch((error) => {
                    console.log(error);
                    window.location = "/access";

                });}
            else{
            axios.post("https://adits-notesapp.herokuapp.com/admin/notes/", {owner: sessionStorage.getItem('token')})
            .then(response => {
                this.setState({ notes: response.data });
            })
            .catch((error) => {
                console.log(error);
           //     window.location = "/access";
            });}
    }


    render(){
        return(
            <div>
            <Navbar />
            <table>
            <thead>
                        <tr>
                            <td>
                                <button type="button" name="All" className="circle" onClick={this.changeView}>All</button></td>
                                <td><button type="button" name="Day" className="circle" onClick={this.changeView}>Today</button></td>
                                <td><button type="button" name="Tom" className="circle" onClick={this.changeView}>Tomorrow</button></td>
                                <td><button type="button" name="Week" className="circle" onClick={this.changeView}>Week</button></td>
                                <td><button type="button" name="Recuring" className="circle" onClick={this.changeView}>Recuring</button></td>
                                <td><button type="button" name="Archive" className="circle" onClick={this.changeView}>Archive</button></td>
                        
                        </tr>
                    </thead>
                </table>
                
                <table className="table table-hover table-bordered print" >
                    <tbody>
                        {this.notesList()}
                    </tbody>
                </table>
                
            </div>
        )
    }
}