import {Component} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import Yash from "../IMG-20200922-WA0008.jpg"
import resume from "../resume.pdf"
import jhu from "../jhu.png"
import shs from "../shslogo.png"
import columbia from "../columbia.png"
import usc from "../usc.png"
import scf from "../scf.png"
import lion from "../lion.png"
import unicef from "../unicef.png"

export default class Home extends Component {


    render(){
        return(<div >
        <a href="/access"><button className="hiddenbutton">Admin Access</button></a>
       <div className="title" > Hi I'm Yash! Nice to meet you!</div> 
        <img className="profile" alt="Yash Mehta" src={Yash}></img> 
        <div className="des"> I'm currently an Investment Analyst Intern @Jhaveri Capital and a Senior @ Scarsdale High School. I am currently in the process of applying to colleges and universities to pursue a degree in Business Administration.</div>
        <div className="porttitle"> My Portfolio</div>
        <table className="table table-striped"><thead><tr className="links" cellSpacing=".5em"><td className="details" ><a className="link" href="#education">Education</a></td><td className="details" ><a className="link" href="#leadership">Leadership Experience</a></td><td className="details" ><a className="link" href="#extra">Extracurricular</a></td><td className="details" ><a className="link" href={resume} download>Resume</a></td></tr></thead></table>
        <div className="cat education">
        <div className="section" id ="education">Education</div>
        <table className="table table-striped">
            <tbody>

                <tr className="size"> 
                <td className="details" >
                <h4 className="head">Scarsdale High School</h4>
                <h3 className="role">Student</h3>
                <p className="subhead">Scarsdale, NY, September 2018 - Present</p>
                <ul>
                <li>Relevant Coursework: AT Calculus AB, AT Computer Science, AT Statistics.</li>
              
                </ul>
                </td>
                <td className="details" ><img className="icon" alt="SHS" src={shs}></img> </td>
                </tr>

                <tr className="size"> 
                <td className="details" >
                <h4 className="head">University of Southern California</h4>
                <h3 className="role">Student</h3>
                <p className="subhead">Remote, June 2021 - July 2021</p>
                <ul>
                <li>Learning about the intricacies that make a business successful including marketing, operations, finance and leadership.</li>
                </ul>
                </td>
                <td className="details" ><img className="icon" alt="USC" src={usc}></img> </td>
                </tr>

                <tr className="size"> 
                <td className="details" >
                <h4 className="head">Columbia University</h4>
                <h3 className="role">Student</h3>
                <p className="subhead">New York, NY, June 2019 - July 2019</p>
                <ul>
                <li>Gained an understanding of the key principles of entrepreneurship and innovation.</li>
                <li>Presented to a panel of professors, an innovative social media platform that helps cancer patients find support and a community.</li>
                </ul>
                </td>
                <td className="details" ><img className="icon" alt="Columbia" src={columbia}></img> </td>
                </tr>

                <tr className="size"> 
                <td className="details" >
                <h4 className="head">Johns Hopkins University</h4>
                <h3 className="role">Student</h3>
                <p className="subhead">Remote, June 2018 - August 2018</p>
                <ul>
                <li>Employed inductive and deductive reasoning skills to examine their roles in mathematics.</li>
                <li>Explored a wide range of topics in mathematics including symbolic logic, truth tables, and syllogisms.</li>
                </ul>
                </td>
                <td className="details" ><img className="icon" alt="JHU" src={jhu}></img> </td>
                </tr>

            </tbody>
        </table>

        </div>
        <div className="cat leadership">
        <div className="section" id ="leadership">Leadership Experience</div>
        <table className="table table-striped">
            <tbody>

                <tr className="size"> 
                <td className="details" >
                <h4 className="head">Share and Care Kids 4 Kids</h4>
                <h3 className="role">Founder</h3>
                <p className="subhead">USA, April 2018 - Present</p>
                <ul>
                <li>Founded Kids 4 Kids with a vision to help educate at least 500 underprivileged children in India.</li>
                <li>Raised over $37,000 in partnership with Light of Light Trust which helped place 300+ children into
schools in Mumbai.</li>
                </ul>
                </td>
                <td className="details" ><img className="icon" alt="SCF Kids 4 Kids" src={scf}></img> </td>
                </tr>

                <tr className="size"> 
                <td className="details" >
                <h4 className="head">Civic Education Program</h4>
                <h3 className="role">Peer Leader</h3>
                <p className="subhead">Scarsdale, NY, September 2020 - Present</p>
                <ul>
                <li> Selected out of a class of 400 students to lead the peer mentorship program.</li>
                <li> Mentored six first year students transitioning into high school social life and academics.</li>
                </ul>
                </td>
                <td className="details" ><img className="icon" alt="SHS" src={shs}></img> </td>
                </tr>

            </tbody>
        </table>

        </div>

        <div className="cat extra">
        <div className="section" id ="extra">Extracurricular</div>
        <table className="table table-striped">
            <tbody>

                <tr className="size"> 
                <td className="details" >
                <h4 className="head">Lion's Heart</h4>
                <h3 className="role">Award Winner</h3>
                <p className="subhead">Scarsdale, NY, September 2018 - Present</p>
                <ul>
                <li>Engaged in volunteering opportunities such as midnight runs, clothes/food drives, and soup kitchens.</li>
                <li>Obtained the Gold Presidential Service Award for work in children’s education and community outreach reaching over 100 hours of service in one year.</li>
                </ul>
                </td>
                <td className="details" ><img className="icon" alt="Lion's Heart" src={lion}></img> </td>
                </tr>

                <tr className="size"> 
                <td className="details" >
                <h4 className="head">UNICEF Club</h4>
                <h3 className="role">Member</h3>
                <p className="subhead">Scarsdale, NY, January 2018 - Present</p>
                <ul>
                <li>Organized information sessions, fundraisers, and clothing drives to raise awareness about sustainability and children’s education by hosting information sessions, fundraiser</li>
                <li>Raised $1234 personally and led other members to raise a total of $4000 through a popcorn fundraiser</li>
                </ul>
                </td>
                <td className="details" ><img className="icon" alt="Unicef" src={unicef}></img> </td>
                </tr>

                <tr className="size"> 
                <td className="details" >
                <h4 className="head">Scarsdale High School</h4>
                <h3 className="role">Multi Sport Athlete</h3>
                <p className="subhead">Scarsdale, NY, August 2020 - Present</p>
                <ul>
                <li>Represented Scarsdale High School in the Interschool Class AA Division League for Varsity Volleyball and Varsity Soccer.</li>
                <li>Trained four hours daily for one year to improve stamina, aility, and endurance technique.</li>
                </ul>
                </td>
                <td className="details" ><img className="icon" alt="SHS" src={shs}></img> </td>
                </tr>

            </tbody>
        </table>

        </div>
    </div>);
    }

    }