import {Component} from 'react';
import axios from 'axios';
import "./access.css";

export default class Accesss extends Component {

    constructor(props){
        super(props);

        this.onChangePassword = this.onChangePassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state= {
            password: '',
        }
    }

    componentDidMount(){
        if(sessionStorage.getItem('token') !== null)
            window.location = "/admin/notes";
    }
         onChangePassword(e){
           this.setState({
               password: e.target.value
           });
       } 
    
       onSubmit(e){
        e.preventDefault();
        axios.post("https://adits-notesapp.herokuapp.com/login", {name: "yash", password: this.state.password})
            .then( token => setTimeout(() => {  sessionStorage.setItem('token', token.data); window.location = "/admin/notes"; }, 250));
        }

        render(){
            return(
                <div>
                    <h3 className="accesstitle">Welcome Yash!</h3>
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <input type = "password"
                                required
                                className="form-control radius3 securetext"
                                value={this.state.password}
                                onChange= {this.onChangePassword}
                                autoFocus
                                />
                        </div>
                        <div className="form-group">
                            <input type="submit" value="Enter" className="submit radius3"/>
                        </div>
                    </form>
                </div>
            )
        }
}