import { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
//import DatePicker from 'react-horizontal-datepicker';
import ReactNotifications from 'react-browser-notifications';
import "react-datepicker/dist/react-datepicker.css";
import Navbar from "./adnav.component";
import "./notes-list.css";
import icon from "../favicon.ico";

const Note = props => (
    <tr className="fullnote">
        <td><span className="date">{(new Date(props.note.date)).toLocaleString().substring(0, (new Date(props.note.date)).toLocaleString().indexOf('/202'))}:</span> <span className="note"><strong>{props.note.topic}</strong></span></td>
        <td><button className="bt2 radius2" onClick={() => { props.complete(props.note._id) }}>Complete</button> | <Link to={"/admin/notes/edit/" + props.note._id}><button className="bt1 radius2">Edit</button></Link></td>
    </tr>
)

export default class NotesList extends Component {
    constructor(props) {
        super(props);
        this.complete = this.complete.bind(this);
        this.onChangeTopic = this.onChangeTopic.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        
        this.onChangePlan = this.onChangePlan.bind(this);
        this.onChangeofPlan = this.onChangeofPlan.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitEnter = this.onSubmitEnter.bind(this);
        this.changeView = this.changeView.bind(this);
        this.pullNotes = this.pullNotes.bind(this);
        // this.showNotifications = this.showNotifications.bind(this);
        // this.handleClick = this.handleClick.bind(this);
        this.state = {
            topic: '',
            date: new Date(),
            notes: [],
            view: "Day",
            plan: "",
            planid: "",
            lastevent: ""
        }

    }


    
    onChangeTopic(e) {
        this.setState({
            topic: e.target.value
        });
    }

    onChangeDate(date) {
        this.setState({
            date: date
        });
    }

    componentDidMount(){
        this.pullNotes(this.state.view);
        axios.post("https://adits-notesapp.herokuapp.com/admin/notes/plan", {owner: sessionStorage.getItem('token')})
            .then(response => {
                this.setState({ plan: response.data.topic, planid: response.data._id });
            })
            .catch((error) => {
                console.log(error);
            });
        
    }

    complete(id) {
        axios.post("https://adits-notesapp.herokuapp.com/admin/notes/complete/" + id, { complete: true })
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                this.pullNotes(this.state.view);
            }, 250));
    }

    onChangePlan(e){
        this.setState({
            plan: e.target.value
        });    
    }

    onChangeofPlan(e){
        e.preventDefault();
        const note = {
            topic: this.state.plan,
            date: this.state.date,
            complete: false,
            display: true,
            owner: sessionStorage.getItem('token')
        };
        axios.post("https://adits-notesapp.herokuapp.com/admin/notes/update/" + this.state.planid, note)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                this.pullNotes(this.state.view);
             }, 250));
        
        this.showNotifications();
    }

    onSubmit(e) {
        e.preventDefault();
        const note = {
            topic: this.state.topic,
            date: this.state.date,
            archive: this.state.view==="Archive",
            owner: sessionStorage.getItem('token')
        }
    

        axios.post("https://adits-notesapp.herokuapp.com/admin/notes/add", note)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                this.pullNotes(this.state.view);
            }
                , 250));
            this.setState({
                    topic: '',
                    date: new Date()
                });
    }

    onSubmitEnter(event) {
    if (event.key === "Enter") {
        const note = {
            topic: this.state.topic,
            date: this.state.date,
            archive: this.state.view==="Archive",
            owner: sessionStorage.getItem('token')
        }
        axios.post("https://adits-notesapp.herokuapp.com/admin/notes/add", note)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                this.pullNotes(this.state.view);
            }
                , 250));
            this.setState({
                    topic: '',
                    date: new Date()
                });
    }
    
}

showNotifications() {
    // If the Notifications API is supported by the browser
    // then show the notification
    if(this.n.supported()) this.n.show();
  }
 
  handleClick(event) {
    // Do something here such as
    // console.log("Notification Clicked") OR
    // window.focus() OR
    window.open("https://theyashmehta.com/admin/notes/");
 
    // Lastly, Close the notification
    this.n.close(event.target.tag);
  }

    notesList() {
        return this.state.notes.map(currentnote => {
            return <Note note={currentnote} complete={this.complete} key={currentnote._id} />
        });
    }

     changeView(e) {
        if(this.state.lastevent !== "")
            this.state.lastevent.target.className = "circle";
        this.setState({
            view: e.target.name,
            lastevent: e
        });
        e.target.className = "view";
        this.pullNotes(e.target.name);  
    }

    pullNotes(views){
        if (views!=="All"){
            axios.post("https://adits-notesapp.herokuapp.com/admin/notes/"+views,{owner: sessionStorage.getItem('token')})
                .then(response => {
                    this.setState({ notes: response.data });
                })
                .catch((error) => {
                    console.log(error);
                    window.location = "/access";

                });}
            else{
            axios.post("https://adits-notesapp.herokuapp.com/admin/notes/", {owner: sessionStorage.getItem('token')})
            .then(response => {
                this.setState({ notes: response.data });
            })
            .catch((error) => {
                console.log(error);
           //     window.location = "/access";
            });}
    }

    render() {
        return (
            <div className="container-fluid">
                <Navbar />
            
                <div>
 
        <ReactNotifications
          onRef={ref => (this.n = ref)} // Required
          title="Plan Updated" // Required
          body={this.state.plan}
          icon= {icon}
          tag="New Note"
          timeout="10000"
          onClick={event => this.handleClick(event)}
        />
 
      </div>
                <textarea className= "plan" rows="4"
                                    type="text"
                                    value={this.state.plan}
                                    onChange={this.onChangePlan}
                                    placeholder="Write your day plan here!"
                                />

        <input className="plansubmit radius3"
                                    type="submit"
                                    value="Update"
                                    
                                    onClick={this.onChangeofPlan}
                                />
                
                <table className="table table-responsive">
                    <thead>
                        <tr>
                            <td>
                                <button type="button" name="All" className="circle" onClick={this.changeView}>All</button></td>
                                <td><button type="button" name="Day" className="circle" onClick={this.changeView}>Today</button></td>
                                <td><button type="button" name="Tom" className="circle" onClick={this.changeView}>Tomorrow</button></td>
                                <td><button type="button" name="Week" className="circle" onClick={this.changeView}>Week</button></td>
                                <td><button type="button" name="Recuring" className="circle" onClick={this.changeView}>Recuring</button></td>
                                <td><button type="button" name="Archive" className="circle" onClick={this.changeView}>Archive</button></td>
                        
                        </tr>
                    </thead>
                </table>

                <table className="table table-sm table-responsive-sm table-hover ">
                    <tbody>
                    {this.notesList()}
                   
                        <tr> 
                            <td>
                                <DatePicker className="date radius2"//npm install react-datepicker
                                    selected={this.state.date}
                                    onChange={this.onChangeDate}
                                />
                                <input className="newnote radius3"
                                    type="text"
                                    value={this.state.topic}
                                    onChange={this.onChangeTopic}
                                    onKeyPress={this.onSubmitEnter}
                                />
                            </td>
                            
                        
                            <td>
                                <input
                                    type="submit"
                                    value="Create"
                                    className="bt2 radius2"
                                    onClick={this.onSubmit}
                                />
                            </td>

                        </tr>
                    </tbody>
                </table>

            </div>
        )
    }
}