//import {BrowserRouter as Router, Route} from "react-router-dom";
import {BrowserRouter as Router, Route} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"
import NotesList from "./components/notes-list.component";
import EditNote from "./components/edit-note.component"
import Access from "./components/access.component"
import Print from "./components/print.component"
import Home from "./components/home.component";
import useToken from './useToken';


function App() {  
  const token = useToken();
  return (
    <Router>
    
      <div className="container-fluid px-0">
      <Route path="/" exact component={Home}/>
      <br/>
      { token ? ( <div>
      <Route path="/access" exact component={Access}/>
      <Route path="/admin/notes/edit/:id" exact component={EditNote}/>
      <Route path="/admin/notes" exact component={NotesList}/>
      <Route path="/admin/notes/print" exact component={Print}/>
      </div>
      ):
      <Route path="/access" exact component={Access}/>
      }
      </div>
    </Router>
  );
}

export default App;
