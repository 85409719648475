import { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import "./edit-note.css";
// import icon from "../favicon.ico";
// import ReactNotifications from 'react-browser-notifications';

export default class EditExercise extends Component {
    constructor(props) {
        super(props);

        this.onChangeTopic = this.onChangeTopic.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeDetails = this.onChangeDetails.bind(this);
        this.onChangeNext = this.onChangeNext.bind(this);
        this.onChangeComplete = this.onChangeComplete.bind(this);
        this.onChangeArchive = this.onChangeArchive.bind(this);

        this.onSubmit = this.onSubmit.bind(this);
        // this.showNotifications = this.showNotifications.bind(this);
        // this.handleClick = this.handleClick.bind(this);
        this.state = {
            topic: '',
            details: '',
            nextTil: 0,
            complete: false,
            date: new Date(),
            archive: ""
        }
    }

    componentDidMount() {
        //right before displays component
        axios.get("https://adits-notesapp.herokuapp.com/admin/notes/" + this.props.match.params.id)
            .then(response => {
                var val = "";
                if (response.data.archive === true)
                    val = "Unarchive";
                else    
                    val = "Archive";
                this.setState({
                    topic: response.data.topic,
                    date: new Date(response.data.date),
                    details: response.data.details,
                    nextTil: response.data.nextTil,
                    complete: response.data.complete,
                    archive: val
                });
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    onChangeTopic(e) {
        this.setState({
            topic: e.target.value
        });
    }
    onChangeDetails(e) {
        this.setState({
            details: e.target.value
        });
    }

    onChangeDate(date) {
        this.setState({
            date: date
        });
    }

    onChangeNext(e) {
        this.setState({
            nextTil: e.target.value 
        });
    }

    onChangeComplete(e) {
        const note = {
            topic: this.state.topic,
            date: this.state.date,
            details: this.state.details,
            nextTil: this.state.nextTil,
            complete: true,
            archive: this.state.archive === "Unarchive",
            owner: sessionStorage.getItem('token')
        };

        axios.post("https://adits-notesapp.herokuapp.com/admin/notes/update/" + this.props.match.params.id, note)
            .then(res => setTimeout(() => { console.log(res.data); window.location = "/admin/notes"; }, 250));

    }

    onChangeArchive(e) {
        if (this.state.archive === "Archive") {
        this.setState({
            archive: "Unarchive"
        });}
        else{
            this.setState({
                archive: "Archive"
            });}    
    }

    // showNotifications() {
    //     // If the Notifications API is supported by the browser
    //     // then show the notification
    //     if(this.n.supported()) this.n.show();
    //   }
     
    //   handleClick(event) {
    //     // Do something here such as
    //     // console.log("Notification Clicked") OR
    //     // window.focus() OR

    //     window.open("https://www.aditmehta.com/admin/notes/edit/"+this.props.match.params.id);
     
    //     // Lastly, Close the notification
    //     this.n.close(event.target.tag);
    //   }

    onSubmit(e) {
        e.preventDefault();
        const note = {
            topic: this.state.topic,
            date: this.state.date,
            details: this.state.details,
            nextTil: this.state.nextTil,
            complete: this.state.complete,
            archive: this.state.archive === "Unarchive",
            owner: sessionStorage.getItem('token')
        };

        axios.post("https://adits-notesapp.herokuapp.com/admin/notes/update/" + this.props.match.params.id, note)
            .then(res => setTimeout(() => { console.log(res.data); window.location = "/admin/notes"; }, 250));
        // this.showNotifications();
    }

    render() {
        return (
            <div>
            {/* <ReactNotifications
          onRef={ref => (this.n = ref)} // Required
          title="Note Updated" // Required
          onClick={event => this.handleClick(event)}
          body={this.state.topic}
          icon= {icon}
          
        //   timeout="10000"

        /> */}
                <form onSubmit={this.onSubmit}>
                <div className="form-group">
                        <div>
                            <DatePicker //npm install react-datepicker
                            className="edate"
                                selected={this.state.date}
                                onChange={this.onChangeDate}
                            />
                        </div>
                    </div>
                <div className="form-group">
                        <input
                            type="submit"
                            value="Edit Note"
                            className="editsubmit radius3"
                            onChange={this.onSubmit}
                        />
                    </div>


                    <div className="form-group">
                        <textarea className= "topic" rows="2"
                                    type="text"
                                    value={this.state.topic}
                            onChange={this.onChangeTopic}
                                />
                    </div>
                    <div>
                        <label className="ocrlbl"> Occurence: </label>
                        <input
                            type="number"
                            className="ocurr radius3"
                            value={this.state.nextTil}
                             onChange={this.onChangeNext}
                        />
                    </div>
                    <div className="form-group">
                    <textarea className= "detail" rows="4"
                                    type="text"
                                    placeholder="Details"
                                value={this.state.details}
                               onChange={this.onChangeDetails}
                                />
                    </div>

                    <input type="button" className="bt1 archive radius3" value={this.state.archive} onClick={this.onChangeArchive}/>
                    <input type="button" className="bt1 complete radius3" value="Complete" onClick={this.onChangeComplete}/>
                    {/* <div className="form">

<label className="radiohead">Complete </label>
<div className="radio">
    <label className="radioparty">
        <input className="radioparty" type="radio" value="false" checked={this.state.complete === false} onChange={this.onChangeComplete} />
False/
</label>
    <label className="radioparty">
        <input className="radioparty" type="radio" value="true" checked={this.state.complete === true} display="false" onChange={this.onChangeComplete} />
True
</label>
</div>
</div>
                    */}
                </form> 
            </div>
        )
    }
}